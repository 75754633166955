<script setup lang="ts">
import { ref } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

</script>

<template>
    <div class="text_titre">
        <h1>Dossier d'inscription</h1>
    </div>
    <section class="containerInscription">
        <h1>Le formulaire d'inscription pour la saison 2024-2025 est disponible ci-dessous :</h1>
        <div class="information">
            <h3>Informations importante :</h3>
            <ul>
                <li>La licence running : insriptions aux seules courses du Challenge des Dunes de Flandre (pas de course à label).</li>
                <li>Le certificat médical est obligatoire pour tous les nouveaux adhérents adultes ainsi que les enfants qui ont eu des problèmes de santé dans l'année.</li>
                <li>Pour tous les licenciés: Vous devez valider la charte étique sur votre compte FFA, afin que votre licence soit activée. (<a href="https://webservicesffa.athle.fr/Siffa-Acteur2/AppMain_Login.aspx?ReturnUrl=%2fSiffa-Acteur2">Lien du site</a>).</li>
                <li>Le Pass'sport de la ville et de l'état est accepté (il faudra nous transmet en pièce jointe une copie avec les codes).</li>
                <li>Les Coupons-sport ANCV sont acceptés <b>avec une majoration de 5€</b> (frais de gestion ANCV)</li>
            </ul>
        </div>
        <div class="containerButton">
            <a target="_blank" href="Flyers2024-2025.pdf">Flyer d'information</a>
            <a>Fiche inscription club (prochainement)</a>
            <a>Fiche inscription FFA (prochainement)</a>
            <a>Questionnaire de santé (prochainement)</a>
        </div>
    </section>
</template>
<style scoped>
.text_titre {
    width: 30%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 5%;
    text-align: center;
    border: 5px solid #fdce08;
}
.containerInscription {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 80%;
    margin: auto;
}
.containerInscription h1{
    font-size: 210%
}
.information{
    width: 100%;
}
.information h2{
    margin-top: 0;
}
.information h3{
    text-decoration: underline;
}
.containerButton{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.containerButton a{
    padding: 2%;
    width: 34%;
    margin: auto;
    margin-top: 2%;
    font-size: 120%;
    border-radius: 10px;
    border: 4px solid #0872b1;
    color: #000;
    cursor: pointer;
    text-decoration: none;
    text-align: center
}
.containerButton a:hover{
    background-color: #0872b1;
    color: #fff;
}
@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
  .containerInscription h1{
    font-size: 130%;
  }
  .containerButton a{
    width: 100%;
    padding: 5%;
  }
}
</style>
