import axios from 'axios'

const API_URL = 'https://ogsa-api-3d50c2c1176b.herokuapp.com/api/'

class ArticlesService {
  getAllArticles () {
    return axios
      .get(API_URL + 'articles')
  }

  getNextArticles () {
    return axios
      .get(API_URL + 'articles/Next')
  }
}

export default new ArticlesService()
