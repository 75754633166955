<script setup lang="ts">

import CoursesService from '../services/courses.service.js'
import moment from 'moment'
import 'moment/dist/locale/fr'
import router from '@/router'
import { ref } from 'vue'
moment.locale('fr')

const courses = ref()
CoursesService.getAllCourses().then(
  (response: { data: any }) => {
    courses.value = response.data
  },
  (error: any) => {
    courses.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

const courseNext = ref()
CoursesService.getNextCourse().then(
  (response: { data: any }) => {
    courseNext.value = response.data
  },
  (error: any) => {
    courseNext.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

function getImageUrl (name: any) {
  return '/Flyers/' + name
}
function getImageUrlBack (name: any) {
  return '/Background/' + name
}
</script>

<template>
    <div class="text_titre">
        <h1>Hors Stade</h1>
    </div>

    <section class="containerNext" v-if="courseNext">
        <div class="nextCourse">
            <h3>à la une</h3>
            <div class="cardNextCourse">
                <div class="containerLeft">
                    <img :src="courseNext.Background" class="imgBackground" alt="">
                    <div class="containerShadowRight1">
                        <div class="containerDate">
                            <h4>{{moment(courseNext.Datecourse_courses).format('dddd DD MMMM YYYY')}}</h4>
                        </div>
                        <div class="containerInfo">
                            <div class="containerType">
                                <h4>{{ courseNext.Type}}</h4>
                            </div>
                            <h2>{{ courseNext.Nomcourse_courses }}</h2>
                            <div class="listParcours">
                                <div class="parcourirs" v-for="parcours in courseNext.parcourirs" v-bind:key="parcours.id">
                                   <h4>{{ parcours.Distance }} Km</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="descNextCourse">
                    <h3>{{ courseNext.Nomcourse_courses }}</h3>
                    <div class="date">
                        <div class="containerLocation">
                            <img src="@/assets/icons/place.png" alt="">
                            <h4>{{ courseNext.Adresse }}, {{ courseNext.Ville }} ({{ courseNext.CodePostal }})</h4>
                        </div>
                        <div class="containerLocation">
                            <img src="@/assets/icons/calendar.png" alt="">
                            <h4>Inscription jusqu'au {{ moment(courseNext.Datecourse_courses).locale('fr').subtract(10, 'd').format('dddd DD MMMM YYYY') }}</h4>
                        </div>
                        <div class="containerLocation" v-if="courseNext.PriseEnCharge === 'Oui'">
                            <img src="@/assets/icons/check.png" alt="">
                            <h4>Course prise en charge</h4>
                        </div>
                        <div class="containerLocation" v-if="courseNext.PriseEnCharge === 'Non'">
                            <img src="@/assets/icons/close.png" alt="">
                            <h4>Course non prise en charge</h4>
                        </div>
                    </div>
                    <div class="containerButton">
                        <RouterLink class="butStyle" :to="{ name: 'course', params: {  id: courseNext.id} }">En savoir plus</RouterLink>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <div class="containerCourses" v-if="courses">
        <h3>A venir</h3>
        <h2>Les courses 2024-2025</h2>
        <div class="containerListCourse" v-if="courses">
            <div v-for="data in courses" v-bind:key="data.id" class="cardCourse">
                <div class="cardCourseImg">
                    <img :src="data.Background" alt="">
                    <div class="containerShadow">
                        <div class="containerDate1">
                            <h4>{{moment(data.Datecourse_courses).format('dddd DD MMMM YYYY')}}</h4>
                        </div>
                        <div class="containerInfo1">
                            <div class="containerType1">
                                <h4>{{ data.Type }}</h4>
                            </div>
                            <h2>{{ data.Nomcourse_courses }}</h2>
                            <div class="listParcours1">
                                <div class="parcourirs1" v-for="parcours in data.parcourirs" v-bind:key="parcours.id">
                                   <h4>{{ parcours.Distance }} Km</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="date">
                        <div class="containerLocation1">
                            <img src="@/assets/icons/place.png" alt="">
                            <h4>{{ data.Adresse }}, {{ data.Ville }} ({{ data.CodePostal }})</h4>
                        </div>
                        <div class="containerLocation1">
                            <img src="@/assets/icons/calendar.png" alt="">
                            <h4>Inscription jusqu'au {{ moment(data.Datecourse_courses).locale('fr').subtract(10, 'd').format('dddd DD MMMM YYYY') }}</h4>
                        </div>
                        <div class="containerLocation1" v-if="data.PriseEnCharge === 'Oui'">
                            <img src="@/assets/icons/check.png" alt="">
                            <h4>Course prise en charge</h4>
                        </div>
                        <div class="containerLocation1" v-if="data.PriseEnCharge === 'Non'">
                            <img src="@/assets/icons/close.png" alt="">
                            <h4>Course non prise en charge</h4>
                        </div>
                    </div>
                <RouterLink class="butStyleCourse" :to="{ name: 'course', params: {  id: data.id} }">En savoir plus</RouterLink>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .textAlert{
        color: #ff0000;
        text-align: center;
    }
    .containerNext{
        width: 96%;
        height: 60vh;
        margin-top: 4%;
        padding: 2%;
        background-color: #d9d9d9;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        align-self: center;
        align-items: center;
    }
    .nextCourse{
        width: 80%;
        height: 40vh;
        padding: 1%;
        display: flex;
        flex-direction: column;
    }
    .nextCourse h3{
        margin: 0;
        text-transform: uppercase;
        color: #0872b1
    }
    .nextCourse h2{
        margin: 0;
        text-transform: uppercase;
        font-size: 300%;
        color: #000;
        font-family: 'ArquitectaBlack';
    }
    .cardNextCourse{
        width: 100%;
        margin-top: 4%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .containerLeft{
        width: 46%;
        height: 45vh;
    }
    .imgBackground{
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
    .containerShadowRight1{
        position: absolute;
        width: 35.3%;
        height: 45vh;
        background-color: #1a12128c;
        display: flex;
        flex-direction: column;
    }
    .containerDate{
        align-self: flex-end;
        margin-right: 2%;
    }
    .containerDate h4{
        color: #fff;
        font-size: 120%;
        text-transform: uppercase;
    }
    .containerInfo{
        width: 90%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }
    .listParcours{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 2%;
    }
    .parcourirs{
        padding: 1%;
        color: #fff;
        margin-right: 2%;
        border: 1px solid #fff;
        border-radius: 5px;
    }
    .parcourirs h4{
        margin: 0;
    }
    .containerShadowRight1 h2{
        width: 100%;
        color: #fff;
        font-size: 200%;
        font-family: 'ArquitectaBlack';
    }
    .containerType{
        padding: 2%;
        background-color: #000;
        color: #fff;
        text-align: center;
        border-radius: 5px;
    }
    .containerType h4{
        margin: 0;
        text-transform: uppercase;
    }
    .descNextCourse{
        width: 52%;
    }
    .date{
        width: 100%;
        margin-top: 2%;
    }
    .date h2{
        text-transform: uppercase;
        font-family: 'ArquitectaBlack';
        font-size: 300%;
        margin: 0;
        margin-bottom: 2%;
    }
    .containerLocation{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 2%;
    }
    .containerLocation img{
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-right: 1%;
    }
    .containerLocation h4{
        margin: 0;
    }
    .descNextCourse h3{
        font-size: 250%;
        text-transform: uppercase;
        font-family: 'ArquitectaBlack';
        color: #000;
        margin-top: 2%;
        margin-bottom: 2%
    }
    .descNextCourse h5{
        font-size: 150%;
        margin: 0;
    }
    .descNextCourse ul{
        height: 20vh;
    }
    .descNextCourse li{
        list-style: none;
    }
    .containerButton{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 2%;
    }
    .butStyle{
        padding: 2%;
        text-decoration: none;
        color: #fff;
        font-size: 100%;
        border-radius: 5px;
        background-color: #0872b1;
        text-align: center;
        align-self: center;
        text-transform: uppercase;
    }
    .containerCourses{
        width: 80%;
        margin: auto;
        margin-top: 4%;
    }
    .containerCourses h3{
        margin: 0;
        text-transform: uppercase;
        color: #0872b1
    }
    .containerCourses h2{
        margin: 0;
        text-transform: uppercase;
        font-size: 300%;
        color: #000;
        font-family: 'ArquitectaBlack';
    }
    .containerListCourse{
        width: 100%;
        margin-top: 2%;
        display: flex;
        flex-wrap: wrap;
    }
    .cardCourse{
        width: 31%;
        margin: 1%;
        display: flex;
        flex-direction: column;
    }
    .cardCourseImg{
        width: 100%;
        height: 34vh;
        display: flex;
        margin-bottom: 2%;
    }
    .cardCourse img{
        width: 100%;
        height: 34vh;
        object-fit: cover;
    }
    .containerShadow{
        position: absolute;
        width: 24.8%;
        height: 34vh;
        background-color: #1a12128c;
        display: flex;
        flex-direction: column;
    }
    .containerDate1{
        align-self: flex-end;
        margin-right: 2%;
    }
    .containerDate1 h4{
        color: #fff;
        font-size: 120%;
        text-transform: uppercase;
    }
    .containerInfo1{
        width: 90%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }
    .listParcours1{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-top: 2%;
    }
    .parcourirs1{
        padding: 1%;
        color: #fff;
        margin-right: 2%;
        border: 1px solid #fff;
        border-radius: 5px;
    }
    .parcourirs1 h4{
        margin: 0;
    }
    .containerShadow h2{
        width: 100%;
        color: #fff;
        font-size: 200%;
        font-family: 'ArquitectaBlack';
    }
    .containerType1{
        padding: 2%;
        background-color: #000;
        color: #fff;
        text-align: center;
        border-radius: 5px;
    }
    .containerType1 h4{
        margin: 0;
        text-transform: uppercase;
    }
    .containerLocation1{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 4%;
    }
    .containerLocation1 img{
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-right: 1%;
    }
    .containerLocation1 h4{
        margin: 0;
        font-size: 90%;
    }
    .butStyleCourse{
        padding: 2%;
        text-decoration: none;
        color: #fff;
        font-size: 100%;
        border-radius: 5px;
        background-color: #0872b1;
        text-align: center;
        text-transform: uppercase;
        align-self: flex-end;
        margin-top: 5%;
    }
    @media screen and (min-width : 1850px) {
        .descCourse{
            height: 14vh;
        }
        .butStyleCourse{
            margin-left: 70%;
        }
    }
    @media screen and (max-width : 1024px) {
        .text_titre {
            margin-top: 5%;
            width: 80%;
            font-size: 80%;
        }
        .containerNext{
            margin-top: 10%;
            height: 80vh;
        }
        .nextCourse{
            width: 90%;
            height: 80vh;
            margin-top: 4%;
        }
        .containerLeft{
            width: 100%;
            height: 30vh;
        }
        .containerLeft img{
            height: 30vh;
        }
        .containerShadowRight1{
            width: 86.3%;
            height: 30vh;
            font-size: 75%;
        }
        .descNextCourse{
            width: 100%;
            margin-top: 4%;
            font-size: 90%;
        }
        .containerType{
            padding: 2%;
            margin-bottom: 2%
        }
        .date h2{
            margin-bottom: 4%;
        }
        .butStyle{
            width: 100%;
            padding: 4%;
            margin-top: 4%;
        }
        .containerCourses{
            width: 90%;
            margin-top: 10%;
        }
        .containerCourses h2{
            font-size: 200%;
        }
        .cardCourse{
            width: 100%;
            font-size: 90%;
            margin-top: 4%;
            margin-bottom: 6%;
        }
        .cardCourseImg{
            height: 30vh;
            margin-bottom: 1%
        }
        .cardCourseImg img{
            height: 30vh;
        }
        .containerShadow{
            width: 88.1%;
            height: 30vh;
        }
        .containerType1{
            padding: 2%;
            margin-bottom: 2%
        }
    }
</style>
