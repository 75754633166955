<script setup lang="ts">

import AlbumsService from '../services/albums.service.js'
import { ref } from 'vue'
import { useRoute } from 'vue-router'

const route = useRoute()

const albums = ref(null)
AlbumsService.getAlbum(route.params.id).then(
  (response: { data: null; }) => {
    albums.value = response.data
  },
  (error: { response: { data: { message: any; }; }; message: any; toString: () => any; }) => {
    albums.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)
</script>

<template>
    <div class="text_titre" v-if="albums">
        <h1>{{albums.Nom}}</h1>
    </div>

    <section class="galerie" v-if="albums">
        <!--<h1 v-for="photo in photos" :key="photo.id">{{ photo.id }}</h1>-->
        <!--<img src="http://localhost:3000/uploadsFile/" />-->
        <masonry-wall :items="albums.photos" :ssr-columns="1" :column-width="300" :gap="10" :max-columns="4" style="margin-top: 5%;">
            <template #default="{ item }">
                <img class="photos" :src="item.Source" alt="" />
            </template>
        </masonry-wall>
        <!--<h2>Les photos seront mis prochainement</h2>-->
    </section>

</template>

<style scoped>
.text_titre {
  width: 40%;
  margin: auto;
  margin-top: 2%;
  margin-bottom: 5%;
  text-align: center;
  border: 5px solid #fdce08;
}
.galerie{
    width: 80%;
    margin: auto;
}
.photos{
    width: 100%;
}
@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
}
</style>
