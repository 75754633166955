<script setup lang="ts">
import { ref } from 'vue'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

</script>

<template>
    <div class="text_titre">
        <h1>Boutique du club</h1>
    </div>
    <section class="containerBoutique">
        <img class="pack web" src="@/assets/pack.png" alt="">
        <img class="pack mobile" src="@/assets/packMobile.png" alt="">
        <div class="headerBoutique">
            <h2>Les produits du club</h2>
            <h3>Pour plus informations rapprocher vous des entraîneurs</h3>
        </div>
        <div class="listArticle">
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/VESTE.png" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Veste de pluie Homme</h2>
                        <h2>39,00€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>S</h4>
                            </div>
                            <div>
                                <h4>M</h4>
                            </div>
                            <div>
                                <h4>L</h4>
                            </div>
                            <div>
                                <h4>XL</h4>
                            </div>
                            <div>
                                <h4>XXL</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/VESTE.png" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Veste de pluie Femme</h2>
                        <h2>39,00€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>S</h4>
                            </div>
                            <div>
                                <h4>M</h4>
                            </div>
                            <div>
                                <h4>L</h4>
                            </div>
                            <div>
                                <h4>XL</h4>
                            </div>
                            <div>
                                <h4>XXL</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/VESTE.png" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Veste de pluie Enfant</h2>
                        <h2>35,00€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>6 ans</h4>
                            </div>
                            <div>
                                <h4>8 ans</h4>
                            </div>
                            <div>
                                <h4>10 ans</h4>
                            </div>
                            <div>
                                <h4>12 ans</h4>
                            </div>
                            <div>
                                <h4>14 ans</h4>
                            </div>
                            <div>
                                <h4>16 ans</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/pantalonErima.jpg" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Pantalon Adulte</h2>
                        <h2>18,00€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>S</h4>
                            </div>
                            <div>
                                <h4>M</h4>
                            </div>
                            <div>
                                <h4>L</h4>
                            </div>
                            <div>
                                <h4>XL</h4>
                            </div>
                            <div>
                                <h4>XXL</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/pantalonErima.jpg" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Pantalon Enfant</h2>
                        <h2>15,00€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>6 ans</h4>
                            </div>
                            <div>
                                <h4>8 ans</h4>
                            </div>
                            <div>
                                <h4>10 ans</h4>
                            </div>
                            <div>
                                <h4>12 ans</h4>
                            </div>
                            <div>
                                <h4>14 ans</h4>
                            </div>
                            <div>
                                <h4>16 ans</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/Gourde.jpg" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Gourde</h2>
                        <h2>5,00€</h2>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/tshirt.png" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>T-Shirt Adulte</h2>
                        <h2>18,00€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>S</h4>
                            </div>
                            <div>
                                <h4>M</h4>
                            </div>
                            <div>
                                <h4>L</h4>
                            </div>
                            <div>
                                <h4>XL</h4>
                            </div>
                            <div>
                                <h4>XXL</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/tshirt.png" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>T-shirt Enfant</h2>
                        <h2>15,00€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>6 ans</h4>
                            </div>
                            <div>
                                <h4>8 ans</h4>
                            </div>
                            <div>
                                <h4>10 ans</h4>
                            </div>
                            <div>
                                <h4>12 ans</h4>
                            </div>
                            <div>
                                <h4>14 ans</h4>
                            </div>
                            <div>
                                <h4>16 ans</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/sweat1.jpg" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Sweat Athlétisme Adulte</h2>
                        <h2>24,00€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>S</h4>
                            </div>
                            <div>
                                <h4>M</h4>
                            </div>
                            <div>
                                <h4>L</h4>
                            </div>
                            <div>
                                <h4>XL</h4>
                            </div>
                            <div>
                                <h4>XXL</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/sweat1.jpg" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Sweat Athlétisme Enfant</h2>
                        <h2>20,00€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>6 ans</h4>
                            </div>
                            <div>
                                <h4>8 ans</h4>
                            </div>
                            <div>
                                <h4>10 ans</h4>
                            </div>
                            <div>
                                <h4>12 ans</h4>
                            </div>
                            <div>
                                <h4>14 ans</h4>
                            </div>
                            <div>
                                <h4>16 ans</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/sweat2.jpg" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Sweat Marche Nordique</h2>
                        <h2>25,00€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>S</h4>
                            </div>
                            <div>
                                <h4>M</h4>
                            </div>
                            <div>
                                <h4>L</h4>
                            </div>
                            <div>
                                <h4>XL</h4>
                            </div>
                            <div>
                                <h4>XXL</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/POLO.png" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Polo Homme</h2>
                        <h2>7,50€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>S</h4>
                            </div>
                            <div>
                                <h4>M</h4>
                            </div>
                            <div>
                                <h4>L</h4>
                            </div>
                            <div>
                                <h4>XL</h4>
                            </div>
                            <div>
                                <h4>XXL</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/POLO.png" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Polo Femme</h2>
                        <h2>7,50€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>S</h4>
                            </div>
                            <div>
                                <h4>M</h4>
                            </div>
                            <div>
                                <h4>L</h4>
                            </div>
                            <div>
                                <h4>XL</h4>
                            </div>
                            <div>
                                <h4>XXL</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="article">
                <img class="imgArticle" src="@/assets/Boutique/POLO.png" alt="">
                <div class="textArticle">
                    <div class="header">
                        <h2>Polo Enfant</h2>
                        <h2>7,50€</h2>
                    </div>
                    <div class="containerTaille">
                        <h3>Taille :</h3>
                        <div class="taille">
                            <div>
                                <h4>6 ans</h4>
                            </div>
                            <div>
                                <h4>8 ans</h4>
                            </div>
                            <div>
                                <h4>10 ans</h4>
                            </div>
                            <div>
                                <h4>12 ans</h4>
                            </div>
                            <div>
                                <h4>14 ans</h4>
                            </div>
                            <div>
                                <h4>16 ans</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.text_titre {
    width: 30%;
    margin: auto;
    margin-top: 2%;
    margin-bottom: 5%;
    text-align: center;
    border: 5px solid #fdce08;
}
.containerBoutique {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin: auto;
}
.pack{
    width: 100%;
    border-radius: 10px;
}
.mobile{
    display: none;
}
.headerBoutique{
    margin-top: 2%;
    margin-bottom: 2%;
}
.headerBoutique h3{
    font-weight: 100;
}
.listArticle{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.article{
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-bottom: 4%;
    background-color: #fff;
    border-radius: 10px
}
.imgArticle{
    background-color: #fff;
    width: 80%;
    height: 300px;
    object-fit: contain;
    padding: 4%;
    align-self: center;
    align-items: center;
    border-radius: 10px;
}
.header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 4%;
}
.header h2:nth-child(1){
    width: 60%;
}
.textArticle{
    padding: 4%
}
.textArticle h2{
    font-weight: bolder;
    margin: 0;
    color: #000;
}
.textArticle h3{
    font-weight: bolder;
    margin-bottom: 0;
    color: #000;
}
.containerTaille {
    display: flex;
    flex-direction: row;
}
.containerTaille h3{
    margin: 0;
}
.taille{
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    margin-left: 2%
}
.taille div{
    padding: 2%;
    border-radius: 5px;
    background-color: #0872b1;
    margin-right: 4%;
    margin-bottom: 2%;
    align-items: center;
}
.taille h4{
    color: #fdce08;
    margin: 0;
    font-size: 110%;
    font-weight: bold;
    text-align: center;
}
@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
  .article{
    width: 100%;
    font-size: 90%;
  }
  .web{
    display: none;
  }
  .mobile{
    display: block;
  }
}
</style>
