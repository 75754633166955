<script setup lang="ts">

import { defineProps } from 'vue'

const data = defineProps({
  title: String,
  description1: String,
  description2: String,
  description3: String,
  image: String,
  publie: String,
  categorie: String,
  date: String
})
</script>
<template>
    <div style="background-color: #ffff; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;">
      <img class="imageArticle" v-if="data.image" :src="data.image"  alt="">
      <div v-if="data.title && data.description1" style="padding: 4%;">
        <h1>{{ data.title }}</h1>
        <p style="padding-left: 2%; padding-right: 2%;">{{ data.description1 }}</p>
        <p style="padding-left: 2%; padding-right: 2%;">{{ data.description2 }}</p>
        <p style="padding-left: 2%; padding-right: 2%;">{{ data.description3 }}</p>
        <div class="footerArticle">
          <div>
            <h5>Publié le {{data.date}}</h5>
            <h5>{{data.publie}}</h5>
          </div>
          <div class="categories">{{data.categorie}}</div>
        </div>
      </div>
    </div>
  </template>

  <style scoped>
  .imageArticle{
    width: 100%;
  }
  .actu {
    width: 80%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  h1{
    color: #0872b1;
    margin-top: -1%;
  }
  .categories{
    min-width: 30%;
    text-align: center;
    padding: 1%;
    background-color: #fdce08;
    border-radius: 5px;
  }
  .footerArticle{
    margin-top: 1%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .footerArticle h5{
    margin-bottom: 0;
    margin-top: 0;
  }
  .pagination{
    width: 30%;
    margin: auto;
    margin-top: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .pagination button{
    width: 36px;
    border: none;
    padding: 1%;
    background-color: #0872b1;
    color: #fff;
    font-size: 150%;
    border-radius: 50px;
  }

  </style>
