<template>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
@font-face {
  font-family: "Arquitecta";
  src: url('./assets/font/Arquitecta/Arquitecta.otf');
}
@font-face {
  font-family: "ArquitectaBlack";
  src: url('./assets/font/Arquitecta/ArquitectaBlack.otf');
}
.eveil{
  background-color: #c76aa1;
}
.poussins{
  background-color: #5ec268;
}
.benjamins{
  background-color: #ebb44e;
}
.minimes{
  background-color: #c1cb33;
}
.cadets{
  background-color: #e14545;
}
.fond{
  background-color: #ff8787;
}
.running{
  background-color: #7cb8fd;
}
.marche{
  background-color: #aa86ed;
}
.eveilText{
  color: #c76aa1;
}
.poussinsText{
  color: #5ec268;
}
.benjaminsText{
  color: #ebb44e;
}
.minimesText{
  color: #c1cb33;
}
.cadetsText{
  color: #e14545;
}
.fondText{
  color: #ff8787;
}
.runningText{
  color: #7cb8fd;
}
.marcheText{
  color: #aa86ed;
}

</style>
