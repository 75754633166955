import axios from 'axios'

const API_URL = 'https://ogsa-api-3d50c2c1176b.herokuapp.com/api/'

class AlbumsService {
  getAllAlbums () {
    return axios
      .get(API_URL + 'albums')
  }

  getNextAlbums () {
    return axios
      .get(API_URL + 'albums/Next')
  }

  getAlbum (id) {
    return axios
      .get(API_URL + 'album/' + id)
  }
}

export default new AlbumsService()
