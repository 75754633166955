<script setup lang="ts">

import { ref } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import CoursesService from '../services/courses.service.js'
import CoureursService from '../services/coureur.service.js'
import router from '@/router'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

const NomPrenom = ref('')
const NumLic = ref('')
const Parcours = ref('')
const route = useRoute()

const course = ref(null)
CoursesService.getCourse(route.params.id).then(
  (response) => {
    course.value = response.data
  },
  (error: any) => {
    course.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

const coureurs = ref(null)
CoureursService.getCoureurs().then(
  (response) => {
    coureurs.value = response.data
  },
  (error: any) => {
    coureurs.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

const inscrire = ref(null)
CoursesService.getInscrit(route.params.id).then(
  (response) => {
    inscrire.value = response.data
  },
  (error: any) => {
    inscrire.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

function getImageUrl (name: string) {
  return '/Flyers/' + name
}

function handleSubmit (e: any) {
  const coureur = NomPrenom.value.split(' ')
  let Nom
  let Prenom
  if (coureur.length === 3) {
    Nom = coureur[0] + ' ' + coureur[1]
    Prenom = coureur[2]
  } else {
    Nom = coureur[0]
    Prenom = coureur[1]
  }

  const body = {
    Parcours: Parcours.value,
    Nom: Nom,
    Prenom: Prenom,
    NumLic: NumLic.value
  }

  CoursesService.postInscription(route.params.id, body).then(response => {
  // console.log('File uploaded successfully:', response.data);
    alert('Inscription envoyé')
    router.push('/challenge')
  }).catch(error => {
    console.error('Error uploading file:', error)
    alert('Licencié inconnu')
  })
}
</script>
<template>
    <div class="containerBackground" v-if="course">
        <div class="containerShadow">
            <div class="containerInfo">
                <div class="containerType">
                    <h4>{{course.Type}}</h4>
                </div>
                <h2>{{ course.Nomcourse_courses }}</h2>
            </div>
        </div>
        <img :src="course.Background" alt="">
    </div>

    <section class="containerForm"  v-if="course && coureurs">
        <div class="form">
            <div class="date">
                <h2>{{ moment(course.Datecourse_courses).locale('fr').format('dddd DD MMMM YYYY') }}</h2>
                <div class="containerLocation">
                    <img src="@/assets/icons/place.png" alt="">
                    <h4>{{ course.Adresse }}, {{ course.Ville }} ({{ course.CodePostal }})</h4>
                </div>
                <div class="containerLocation">
                    <img src="@/assets/icons/calendar.png" alt="">
                    <h4>Inscription jusqu'au {{ moment(course.Datecourse_courses).locale('fr').subtract(10, 'd').format('dddd DD MMMM YYYY') }}</h4>
                </div>
                <div class="containerLocation" v-if="course.PriseEnCharge === 'Oui'">
                    <img src="@/assets/icons/check.png" alt="">
                    <h4>Course prise en charge</h4>
                </div>
                <div class="containerLocation" v-if="course.PriseEnCharge === 'Non'">
                    <img src="@/assets/icons/close.png" alt="">
                    <h4>Course non prise en charge</h4>
                </div>
            </div>
            <form ref="myForm" @submit.prevent="handleSubmit($event)" class="formulaire">
                <div class="listeInscrits">
                    <h2>Formulaire d'inscription</h2>
                    <h3 v-if="course.PriseEnCharge === 'Oui'">Uniquement pour les licenciés du club</h3>
                    <!--<RouterLink :to="{name : 'inscrit', params: {id: course.id}}">Liste des inscrits</RouterLink>-->
                </div>
                <div class="containerNomPriseEnCharge" v-if="course.PriseEnCharge === 'Non'">
                    <h3>La course n'est pas prise en charge par le club.</h3>
                    <a class="button" :href="course.Lien">Lien d'inscription</a>
                </div>
                <div class="textInput" v-if="course.PriseEnCharge === 'Oui'">
                    <label for="parcour">Course</label>
                    <select name="parcour" id="parcour" v-model="Parcours" required>
                        <option value="">--Choisissez une course--</option>
                        <option v-for="data in course.parcourirs" v-bind:key="data.Id" :value="data.Numero">{{ data.Heure }} - {{ data.Nom }} - {{ data.Distance }}km</option>
                    </select>

                    <label for="NomPrenom">Nom et Prénom</label>
                    <input type="text" id="NomPrenom" list="licencie" name="NomPrenom" class="input" autocomplete="off" v-model="NomPrenom" required/>

                    <datalist id="licencie">
                        <option  v-for="data in coureurs" v-bind:key="data.Id" :value="data.NumLic_Coureur">{{ data.Nom }} {{ data.Prenom }}</option>
                    </datalist>

                    <label for="NumLic">Numéro de licence</label>
                    <input type="text" id="NumLic" name="NumLic" class="input" v-model="NumLic" required />

                    <button type="submit" class="submit">Envoyer</button>
                </div>
            </form>
            <form ref="myForm" class="formulaire" v-if="course.PriseEnCharge === 'Oui'">
                <div class="listeInscrits">
                    <h2>Liste des inscrits</h2>
                    <!--<RouterLink :to="{name : 'inscrit', params: {id: course.id}}">Liste des inscrits</RouterLink>-->
                </div>
                <div v-if="inscrire" class="containerList">
                    <h4 v-if="inscrire.length == 0">Pas encore d'inscrits</h4>
                    <div style="width: 100%;">
                        <table class="styled-table" v-if="inscrire.length != 0">
                            <thead class="tableHead">
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>Numéro de course</th>
                            </thead>
                            <tbody>
                                <tr v-for="data in inscrire" v-bind:key="data.Id">
                                    <td>{{ data.Nom_Coureur }}</td>
                                    <td>{{ data.Prenom_Coureur }}</td>
                                    <td>{{ data.Numerocourse_parcourir }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
        </div>
        <div class="flyer">
            <img :src="course.Flyer" alt="">
            <a :href="course.SiteWeb" v-if="course.HorsChallenge === 'Oui'">{{ course.SiteWeb }}</a>
            <a href="https://challengeddf.fr/" v-if="course.HorsChallenge === 'Non'">https://challengeddf.fr/</a>
            <!--<div class="divInfoCourse">
                <h2>Site Internet</h2>
                <a :href="course.SiteWeb">{{ course.SiteWeb }}</a>
            </div>
            <a href="">Télecharger le flyers (horaires & règlements)</a>-->
        </div>
    </section>

</template>

<style scoped>
    .containerBackground{
        width: 100%;
        height: 50vh;
    }
    .containerBackground img{
        width: 100%;
        height: 50vh;
        object-fit: cover;
    }
    .containerShadow{
        position: absolute;
        width: 100%;
        height: 50vh;
        background-color: #1a12128c;
        display: flex;
        flex-direction: column;
    }
    .containerInfo{
        max-width: 60%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }
    .containerInfo h2{
        width: 100%;
        color: #fff;
        font-size: 500%;
        font-family: 'ArquitectaBlack';
        text-transform: uppercase;
        margin: 0
    }
    .containerType{
        padding: 1%;
        background-color: #000;
        color: #fff;
        text-align: center;
        border-radius: 5px;
    }
    .containerType h4{
        margin: 0;
        font-size: 110%;
        text-transform: uppercase;
    }
    .text_titre {
        width: 40%;
        margin: auto;
        margin-top: 2%;
        margin-bottom: 5%;
        text-align: center;
        border: 5px solid #fdce08;
    }
    .containerForm{
        width: 80%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 4%;
    }
    .flyer{
        width: 30%;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
    }
    .flyer img{
        width: 100%;
        object-fit: contain;
        margin-bottom: 4%;
        border-radius: 5px;
    }
    .flyer a{
        display: flex;
        justify-content: center;
        color: #0872b1;
        text-decoration: none;
    }
    .form{
        width: 65%;
    }
    .date{
        width: 100%;
        margin-top: 2%;
    }
    .date h2{
        text-transform: uppercase;
        font-family: 'ArquitectaBlack';
        font-size: 300%;
        margin: 0;
        margin-bottom: 2%;
    }
    .containerLocation{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 2%;
    }
    .containerLocation img{
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-right: 1%;
    }
    .containerLocation h4{
        margin: 0;
    }
    .formulaire{
        width: 100%;
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        padding: 2%;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 15%);
    }
    .listeInscrits{
        width: 100%;
        margin-bottom: 2%;
    }
    .listeInscrits h2{
        text-transform: uppercase;
        font-family: 'ArquitectaBlack';
        font-size: 200%;
        margin: 0;
    }
    .listeInscrits h3{
        color: #f00;
    }
    .listeInscrits a{
        color: #0872b1;
        font-size: 120%;
    }
    .textInput{
        padding: 1%;
        width: 96%;
    }
    .textInput label{
        font-size: 120%;
        font-weight: bold;
        align-self: flex-start;
    }
    .textInput select{
        width: 100%;
        font-size: 120%;
        padding: 1%;
        margin-top: 1%;
        margin-bottom: 3%;
        border-radius: 5px;
    }
    .input{
        width: 98%;
        font-size: 120%;
        padding: 1%;
        margin-top: 2%;
        margin-bottom: 3%;
        border-radius: 5px;
        border: 1px solid #767676;
    }
    .submit{
        width: 100%;
        font-size: 120%;
        padding: 2%;
        margin-top: 2%;
        margin-bottom: 2%;
        border-radius: 5px;
        background-color: #0872b1;
        border: none;
        color: #fff;
        cursor: pointer;
    }
    .containerList{
        padding: 1%;
        width: 96%;
    }
    .containerTable{
        width: 100%;
        height: 380px;
        overflow-y: scroll;
    }
    .tableHead{
        text-align: left;
        text-transform: uppercase;
    }
    .styled-table {
        width: 100%;
        border-collapse: collapse;
        font-size: 0.9em;
        font-family: sans-serif;
    }
    .styled-table th,
    .styled-table td {
        padding: 12px 15px;
    }
    .styled-table td:nth-child(3),
    .styled-table th:nth-child(3)
    {
        text-align: center;
    }
    .containerNomPriseEnCharge{
        display: flex;
        flex-direction: column;
    }
    .button{
        background-color: #000;
        color: #fff;
        border: none;
        border-radius: 5px;
        margin: auto;
        margin-bottom: 5%;
        align-items: center;
        padding: 2%;
        font-size: 120%;
        text-decoration: none;
    }
    @media screen and (max-width : 1024px) {
        .text_titre {
            width: 80%;
            margin: auto;
            margin-top: 8%;
            margin-bottom: 6%;
            text-align: center;
            border: 5px solid #fdce08;
        }
        .text_titre h1{
            font-size: 175%;
        }
        .containerBackground{
            height: 30vh;
        }
        .containerBackground img{
            height: 30vh;
        }
        .containerShadow{
            height: 30vh;
        }
        .containerInfo{
            max-width: 90%;
        }
        .containerType{
            padding: 2%;
        }
        .containerInfo h2{
            font-size: 210%;
            margin-top: 2%;
        }
        .flyer{
            width: 100%;
            margin-bottom: 4%;
        }
        .divInfoCourse{
            padding: 2%;
        }
        .divInfoCourse h3{
            color: #3b3a3a;
            font-size: 100%;
            text-transform: uppercase;
        }
        .divInfoCourse h2{
            font-size: 120%;
        }
        .form{
            width: 96%;
            font-size: 90%;
        }
        .date{
            margin-bottom: 5%;
        }
        .date h2{
            margin-bottom: 4%;
        }
        .listeInscrits{
            flex-direction: column;
            margin-bottom: 4%;
        }
        .listeInscrits h2{
            text-align: center;
            margin-top: 2%
        }
        .listeInscrits h3{
            text-align: center
        }
        .formulaire label{
            font-size: 150%;
        }
        .formulaire select{
            font-size: 150%;
            margin-bottom: 6%
        }
        .input{
            margin-bottom: 6%;
            font-size: 150%;
        }
        .submit{
            padding: 4%;
        }
    }
    @media screen and (max-width : 960px) {
        .containerForm{
            width: 90%;
            flex-wrap: wrap-reverse
        }
        .form{
            margin-top: 4%;
            margin-bottom: 10%;
        }
    }
</style>
