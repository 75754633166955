import axios from 'axios'

const API_URL = 'https://ogsa-api-3d50c2c1176b.herokuapp.com/api/'

class EventsService {
  getEvents () {
    return axios
      .get(API_URL + 'events')
  }

  getOneEvents (id) {
    return axios
      .get(API_URL + 'events/' + id)
  }

  getEventsNext () {
    return axios
      .get(API_URL + 'eventsNext')
  }

  getAllCompetition () {
    return axios
      .get(API_URL + 'competitions')
  }

  getCompetition (id) {
    return axios
      .get(API_URL + 'competitions/' + id)
  }

  postEngagement (id, body) {
    return axios
      .post(API_URL + 'competition/' + id + '/engagement', body)
  }
}

export default new EventsService()
