<script setup lang="ts">
import { watchEffect, onMounted } from 'vue'
import { useTimer, useTime } from 'vue-timer-hook'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

const dateEvent = moment('2024-04-22')
const days = dateEvent.diff(moment(), 'days')
const time = new Date(moment().add(days, 'days').format('YYYY-MM-DD')).setSeconds(27000)
const timer = useTimer(time)

onMounted(() => {
  watchEffect(async () => {
    if (timer.isExpired.value) {
      console.warn('IsExpired')
    }
  })
})

</script>
<template>
    <section class="containerFoulees">
        <img class="imgFoulees" src="@/assets/Foulees2024.jpg" alt="">
        <div class="titreFoulees">
            <div class="Foulees_date">
                <h1>Les 13ème Foulées du Stadium</h1>
                <h2>Dimanche 21 Avril 2024</h2>
            </div>
            <div class="timer">
                <div class="timer_item">
                    <div>
                        <h2>{{timer.days}}</h2>
                        <h4>Jours</h4>
                    </div>
                </div>
                <h3>:</h3>
                <div class="timer_item">
                    <div>
                        <h2>{{timer.hours}}</h2>
                        <h4>Heures</h4>
                    </div>
                </div>
                <h3>:</h3>
                <div class="timer_item">
                    <div>
                        <h2>{{timer.minutes}}</h2>
                        <h4>Minutes</h4>
                    </div>
                </div>
                <h3>:</h3>
                <div class="timer_item">
                    <div>
                        <h2>{{timer.seconds}}</h2>
                        <h4>Secondes</h4>
                    </div>
                </div>
            </div>
            <!--<div class="inscription">
                <a href="https://foulees-du-stadium.adeorun.com/" class="ButInscription" target="_blank">S'inscrire</a>
                <h6>Inscriptions jusqu'au jeudi 18 avril 2024</h6>
            </div>-->
        </div>
    </section>
    <section class="presentation">
        <img src="@/assets/flyers/affiche-grandSynthe0001.jpg" alt="">
        <article>
            <h2>Présentation de la course</h2>

            <p>
                Cher (e)s Ami (e)s sportif (ve) s,
            </p>

            <p>
                Le dimanche  21 avril 2024 à Grande-Synthe, aura  lieu la 13ème édition des Foulées du Stadium organisée par l’<b>Olympique Grande-Synthe Athlétisme</b> et la <b>ville de Grande-Synthe</b>.
            </p>

            <p>
                Cette course est la 8èmes course du <b>Challenge des Dunes de Flandre</b> 2024.
            </p>

            <iframe
                width="560"
                height="315"
                src="https://www.tele-astv.fr/videos/2023_04_17_foulees_stadium.mp4"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen>
            </iframe>
        </article>
    </section>
    <section class="courses">
        <h2>Les courses et parcours</h2>
        <article>
            <div class="course">
                <div class="course-photo">
                    <img class="imgCourse" src="@/assets/277768968_3115790665342020_2862745225021976835_n.jpg" alt="Benjamins/Minimes">
                    <h3>Course Pierre Hondermarck</h3>
                </div>
                <h4>Départ: 9H30</h4>
                <h4>Distance: 10 Km</h4>
                <h4>Catégories: Juniors à Masters</h4>
                <!--<a href="https://foulees-du-stadium.adeorun.com/" class="ButInscription2" target="_blank">S'inscrire</a>-->
                <a class="ButParcours" href="10km.pdf"  target="_blank">Résultat 2024</a>
            </div>
            <div class="course">
                <div class="course-photo">
                    <img class="imgCourse" src="@/assets/facebook-292.jpg" alt="Benjamins/Minimes">
                    <h3>Course Benjamins/ Minimes</h3>
                </div>
                <h4>Départ: 10H40</h4>
                <h4>Distance: 2.5 Km</h4>
                <h4>Catégories: Benjamins/ Minimes</h4>
                <!--<a href="https://foulees-du-stadium.adeorun.com/" class="ButInscription2" target="_blank">S'inscrire</a>-->
                <a class="ButParcours" href="BE-MI.pdf"  target="_blank">Résultat 2024</a>
            </div>
            <div class="course">
                <div class="course-photo">
                    <img class="imgCourse" src="@/assets/336717328_785102449622461_921224447052072449_n.jpg" alt="Benjamins/Minimes">
                    <h3>Parcours Endurance</h3>
                </div>
                <h4>Départ: 11H00</h4>
                <h4>Distance: Endurance 8 minutes</h4>
                <h4>Catégories: Baby Athlé/ Eveil Athlé </h4>
                <h4>Pas de classement</h4>
                <!--<a href="https://foulees-du-stadium.adeorun.com/" class="ButInscription2" target="_blank">S'inscrire</a>-->
                <!--<a class="ButParcours" href="Liste inscrits 8 mn FdS 2024 au 19042024 16h.pdf"  target="_blank">Liste des inscrits</a>-->
            </div>
            <div class="course">
                <div class="course-photo">
                    <img class="imgCourse" src="@/assets/336730785_738019624733763_8178175172941357160_n.jpg" alt="Benjamins/Minimes">
                    <h3>Parcours Poussins</h3>
                </div>
                <h4>Départ: 11H05</h4>
                <h4>Distance: 1.3 Km</h4>
                <h4>Catégories: Poussins</h4>
                <h4>Pas de classement</h4>
                <!--<a href="https://foulees-du-stadium.adeorun.com/" class="ButInscription2" target="_blank">S'inscrire</a>-->
                <!--<a class="ButParcours" href="Liste inscrits 1.3 km FdS 2024 au 19042024 16h.pdf" target="_blank">Liste des inscrits</a>-->
            </div>
            <div class="course">
                <div class="course-photo">
                    <img class="imgCourse" src="@/assets/336769299_603889001794730_5059107315211090801_n.jpg" alt="Benjamins/Minimes">
                    <h3>Course Jean-François Lecomte</h3>
                </div>
                <h4>Départ: 11H10</h4>
                <h4>Distance: 5 Km</h4>
                <h4>Catégories: Cadets à Masters</h4>
                <!--<a href="https://foulees-du-stadium.adeorun.com/" class="ButInscription2" target="_blank">S'inscrire</a>-->
                <a class="ButParcours" href="5km.pdf"  target="_blank">Résultat 2024</a>
            </div>
        </article>
    </section>
    <section class="reglements">
        <h2>Règlements et récompenses</h2>
        <div class="containerReglementButton">
            <a class="buttonReglement" href="Reglement2024.pdf" target="_blank" rel="noopener noreferrer">Réglement 2024</a>
            <a class="buttonReglement" href="RecompenseFoulees.pdf" target="_blank" rel="noopener noreferrer">récompenses 2024</a>
        </div>
    </section>
    <section class="info">
        <h2 class="titreInfo">Info pratique</h2>
        <section class="bottom">
        <div class="contactez">
          <div class="contact">
            <img src="@/assets/place.png" alt="">
            <h2>Adresse</h2>
            <h4>Bd Pierre Mendès</h4>
            <h5>59760 Grande-Synthe</h5>
          </div>
          <div class="contact">
            <img src="@/assets/mail.png" alt="">
            <h2>Mail</h2>
            <h4>fouleesdustadium@gmail.com</h4>
          </div>
          <div class="contact">
            <img src="@/assets/logo-messenger-blanc.png" alt="">
            <h2>Messenger</h2>
            <h4>Ogs athlétisme</h4>
          </div>
          <div class="contact2">
            <h2>NOUS CONTACTER</h2>
            <p>N'hésitez pas à nous contacter pour toutes demandes ou renseignements.</p>
            <div class="reseaux">
              <a href="https://www.facebook.com/people/Ogs-athl%C3%A9tisme/100052240903310/">
              <img class="fb" src="@/assets/fFacebook.png" alt="">
              </a>
              <a href="https://www.instagram.com/ogsathletisme/">
                <img class="inst" src="@/assets/instagram.png" alt="">
              </a>
            </div>
          </div>
        </div>
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2510.1372680075915!2d2.315706776536574!3d51.01361287170527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47dc8b1930d077b3%3A0x9e66448a7d185ee8!2sStadium%20du%20Littoral!5e0!3m2!1sfr!2sfr!4v1695310353971!5m2!1sfr!2sfr" class="iframe" style="border:0;" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </section>
    </section>
</template>

<style scoped>

    .containerFoulees{
        width: 100%;
        height: 90vh;
        display: flex;
    }
    .titreFoulees{
        position: absolute;
        width: 80%;
        text-align: center;
        left: 10%;
        top: 10%;
    }
    .imgFoulees{
        width: 100%;
        object-fit: cover;
    }
    .Foulees_date{
        font-size: 400%;
        color: #fdce08;
        font-family: Arial, Helvetica, sans-serif;
    }
    .titreFoulees h2{
        margin: 0;
    }
    .timer{
        width: 60%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        color: #fff;
        background-color: #1a12128c;
        padding: 1%;
        text-align: left;
        margin: auto;
        margin-top: 4%;
    }
    .timer h3{
        font-size: 220%;
    }
    .timer_item{
        display: flex;
        flex-direction: row;
    }
    .timer_item h2{
        font-size: 600%;
        font-family: monospace;
        letter-spacing: 0.2rem;
    }
    .timer_item h4{
        margin-top: -1%;
        margin-bottom: 0;
        font-size: 200%;
        color: #c4c4c4;
    }
    .inscription{
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 2%;
    }
    .inscription h6{
        margin: 0;
        margin-top: 2%;
        font-size: 250%;
        color: #fff;
        text-shadow: 2px 1px 2px #000;
        font-weight: bold;    }
    .ButInscription{
        padding: 2%;
        width: 40%;
        margin: auto;
        font-size: 300%;
        border-radius: 10px;
        border: 4px solid #fdce08;
        color: #fdce08;
        background-color: #1a12128c;
        cursor: pointer;
        text-decoration: none;
    }
    .ButInscription:hover{
        background-color: #0872b1;
        color: #fff;
    }
    .presentation{
        width: 80%;
        margin: auto;
        margin-top: 2%;
        display: flex;
        justify-content: space-around;
    }
    .presentation h2{
        font-size: 250%;
        letter-spacing: 4px;
        font-weight: bold;
        color: #0872b1;
    }
    .presentation article{
        width: 60%;
        padding: 2%;
    }
    .courses{
        width: 80%;
        margin: auto;
        margin-top: 4%;
    }
    .courses h2{
        font-size: 250%;
        letter-spacing: 4px;
        font-weight: bold;
        color: #0872b1;
        text-align: center;
    }
    .courses article{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
    }
    .course{
        width: 30%;
        height: 550px;
        border-radius: 15px;
        background-color: #fff;
        margin-bottom: 4%;
        display: flex;
        flex-direction: column;
    }
    .course-photo{
        width: 100%;
        height: 200px;
        display: flex;
    }
    .imgCourse{
        width: 100%;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .course h3{
        position: absolute;
        margin-left: 1%;
        width: 20%;
        color: #fff;
        padding: 1%;
        background-color: #1a12128c;
        align-self: center;
        text-align: center;
    }
    .course h4{
        text-align: center;
    }
    .ButInscription2{
        padding: 2%;
        width: 60%;
        margin: auto;
        margin-top: 4%;
        font-size: 150%;
        border: 4px solid #0872b1;
        background-color: transparent;
        color: #0872b1;
        cursor: pointer;
        text-align: center;
        text-decoration: none;
    }
    .ButParcours{
        padding: 2%;
        width: 60%;
        margin: auto;
        margin-top: 2%;
        font-size: 150%;
        border: 4px solid #000;
        background-color: transparent;
        color: #000;
        text-align: center;
        text-decoration: none;
        cursor: pointer;
    }
    .ButInscription2:hover{
        background-color: #0872b131;
    }
    .ButParcours:hover{
        background-color: #00000031;
    }
    .reglements{
        width: 80%;
        margin: auto;
        margin-top: 4%;
    }
    .reglements h2{
        font-size: 250%;
        letter-spacing: 4px;
        font-weight: bold;
        color: #0872b1;
        text-align: center;
    }
    .containerReglementButton{
        width: 80%;
        margin: auto;
        margin-top: 5%;
        margin-bottom: 5%;
        display: flex;
        justify-content: space-around;
    }
    .buttonReglement{
        padding: 2%;
        font-size: 200%;
        border-radius: 10px;
        border: 4px solid #fdce08;
        color: #fdce08;
        cursor: pointer;
        text-decoration: none;
    }
    .buttonReglement:hover{
        background-color: #1a12128c;
    }
    .info{
        width: 80%;
        margin: auto;
    }
    .titreInfo{
        font-size: 250%;
        letter-spacing: 4px;
        font-weight: bold;
        color: #0872b1;
        text-align: center;
    }
    .bottom{
        width: 100%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 5%;
    }
    .contactez{
    width: 50%;
    background-color: #0000008c;
    display: flex;
    flex-wrap: wrap;
    padding: 2%;
    }
    .contact h4{
    margin-top: -2%;
    font-weight: 400;
    }
    .contact h5{
    margin-top: -4%;
    font-size: 100%;
    font-weight: 400;
    }
    .contact{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
    }
    .contact img{
    width: 20%;
    border: 2px solid #fff;
    padding: 4%;
    border-radius: 100px;
    }
    .contact2{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #fff;
    }
    .contact2 p{
    margin-top: -2%;
    }
    .reseaux{
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    }
    .fb{
    width: 32%;
    }
    .inst{
    width: 60%;
    }
    .iframe{
    width: 46%;
    }
    @media screen and (max-width : 1300px) {
        .containerFoulees{
            height: 95vh;
        }
        .Foulees_date{
            font-size: 250%;
        }
        .timer_item h2{
            font-size: 400%;
        }
        .timer_item h4{
            font-size: 150%;
        }
    }
    @media screen and (min-width : 300px) and (max-width : 960px) {
        .containerFoulees{
            height: 50vh;
        }
        .titreFoulees{
            width: 100%;
            left: 0;
            font-size: 70%;
        }
        .timer{
            width: 80%;
            text-align: center;
            font-size: 80%;
        }
        .presentation{
            display: flex;
            flex-direction: column;
            font-size: 80%;
        }
        .presentation img{
            width: 100%;
        }
        .presentation article{
            width: 100%;
        }
        .presentation iframe{
            display: none;
        }
        .courses{
            font-size: 80%;
        }
        .courses article{
            display: flex;
            flex-direction: column;
            flex-wrap: none;
        }
        .course{
            width: 100%;
            height: 500px;
            margin-bottom: 10%
        }
        .course h3{
            width: 50%;
            padding: 15px;
            left: 20%;
        }
        .ButParcours{
            width: 80%;
        }
        .containerReglementButton{
            display: flex;
            flex-direction: column;
        }
        .buttonReglement{
            text-align: center;
            margin-bottom: 10%;
        }
        .bottom{
            display: flex;
            flex-direction: column;
            font-size: 80%
        }
        .contactez{
            width: 100%;
        }
        .iframe{
            width: 100%;
            height: 300px;
            margin-top: 10%
        }
    }
</style>
