<script setup lang="ts">

import EventsService from '../services/events.service.js'
import moment from 'moment'
import 'moment/dist/locale/fr'
import router from '@/router'
import { ref } from 'vue'
import CardCompetitions from '@/components/CardCompetitions.vue'
moment.locale('fr')

const competition = ref()
EventsService.getAllCompetition().then(
  (response: { data: any }) => {
    competition.value = response.data
  },
  (error: any) => {
    competition.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

</script>

<template>
    <div class="text_titre">
        <h1>Piste</h1>
    </div>
    <section class="listCompetition">
        <div class="containerCompetition" v-for="data in competition" v-bind:key="data.id">
            <CardCompetitions :competition="data" />
        </div>
    </section>
</template>

<style scoped>
    .textAlert{
        color: #ff0000;
        text-align: center;
    }
    .listCompetition{
        width: 80%;
        margin: auto;
        margin-top: 4%;
    }
    @media screen and (max-width : 1024px) {
        .text_titre {
            width: 80%;
            margin: auto;
            margin-top: 8%;
            margin-bottom: 6%;
            text-align: center;
            border: 5px solid #fdce08;
        }
        .text_titre h1{
            font-size: 175%;
        }
        .listCompetition{
            width: 90%;
        }
    }
</style>
