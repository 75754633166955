<script setup lang="ts">

import { ref } from 'vue'
import { RouterLink, useRoute } from 'vue-router'
import EventsService from '../services/events.service.js'
import CoureursService from '../services/coureur.service.js'
import router from '@/router'
import moment from 'moment'
import 'moment/dist/locale/fr'
moment.locale('fr')

const NomPrenom = ref('')
const NumLic = ref('')
const epreuve1 = ref('')
const epreuve2 = ref('')
const epreuve3 = ref('')
const route = useRoute()
const eventId = ref('')

const events = ref()
const competitions = ref()
EventsService.getCompetition(route.params.id).then(
  (response: { data: any; }) => {
    competitions.value = response.data
    console.log(competitions.value.eventId)
    eventId.value = competitions.value.eventId
    EventsService.getOneEvents(competitions.value.eventId).then(
      (response: { data: any }) => {
        events.value = response.data
      },
      (error: any) => {
        events.value =
        (error.response &&
        error.response.data &&
        error.response.data.message) ||
        error.message ||
        error.toString()
      }
    )
  },
  (error: any) => {
    competitions.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

const coureurs = ref(null)
CoureursService.getCoureurs().then(
  (response) => {
    coureurs.value = response.data
  },
  (error: any) => {
    coureurs.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

const epreuve = [
  {
    Id: 1,
    Nom: '50m'
  },
  {
    Id: 2,
    Nom: '60m'
  }
]

function handleSubmit (e: any) {
  const coureur = NomPrenom.value.split(' ')
  let Nom
  let Prenom
  if (coureur.length === 3) {
    Nom = coureur[0] + ' ' + coureur[1]
    Prenom = coureur[2]
  } else {
    Nom = coureur[0]
    Prenom = coureur[1]
  }

  const body = {
    competitionId: route.params.id,
    NumLic: NumLic.value,
    Nom: Nom,
    Prenom: Prenom,
    Epreuve1: epreuve1.value,
    Epreuve2: epreuve2.value,
    Epreuve3: epreuve3.value
  }

  EventsService.postEngagement(route.params.id, body).then(response => {
  // console.log('File uploaded successfully:', response.data);
    alert('Engagement envoyé')
    router.push('/piste')
  }).catch(error => {
    console.error('Error uploading file:', error)
    alert('Licencié inconnu')
  })
}

</script>
<template>
    <div class="containerBackground" v-if="competitions">
        <div class="containerShadow">
            <div class="containerInfo">
                <div class="event_categ_list" v-if="events">
                    <div class="event_categ_item eveil" v-if="events.Eveil == 'Oui'">
                        <h4>Eveil Athlé</h4>
                    </div>
                    <div class="event_categ_item poussins" v-if="events.Poussins == 'Oui'">
                        <h4>Poussins</h4>
                    </div>
                    <div class="event_categ_item benjamins" v-if="events.Benjamins == 'Oui'">
                        <h4>Benjamins</h4>
                    </div>
                    <div class="event_categ_item minimes" v-if="events.Minimes == 'Oui'">
                        <h4>Minimes</h4>
                    </div>
                    <div class="event_categ_item cadets" v-if="events.Cadets == 'Oui'">
                        <h4>Sprinteur</h4>
                    </div>
                    <div class="event_categ_item fond" v-if="events.Fond == 'Oui'">
                        <h4>Demi-Fond</h4>
                    </div>
                </div>
                <h2>{{ competitions.NomCompetition }}</h2>
            </div>
        </div>
        <img src="@/assets/piste.jpg" alt="">
    </div>
    <section class="containerForm" v-if="competitions">
        <div class="form">
            <div class="date">
                <h2>{{ moment(competitions.DateCompetition).format('dddd DD MMMM YYYY') }}</h2>
                <div class="containerLocation">
                    <img src="@/assets/icons/place.png" alt="">
                    <h4>{{ competitions.Adresse }}, {{ competitions.Ville }} ({{ competitions.CodePostal }})</h4>
                </div>
                <div class="containerLocation">
                    <img src="@/assets/icons/time.png" alt="">
                    <h4>Début de la compétition : {{ moment(competitions.HeureDebut, 'HH:mm:ss').format('HH') }}H{{ moment(competitions.HeureDebut, 'HH:mm:ss').format('mm') }}</h4>
                </div>
                <div class="containerLocation">
                    <img src="@/assets/icons/calendar.png" alt="">
                    <h4>Engagement jusqu'au {{ moment(competitions.DateLimite).format('dddd DD MMMM YYYY') }}</h4>
                </div>
            </div>
            <form ref="myForm" @submit.prevent="handleSubmit($event)" class="formulaire">
                <div class="listeInscrits">
                    <h2>Formulaire d'engagement</h2>
                    <h3>Uniquement pour les licenciés du club</h3>
                </div>
                <div class="textInput">

                    <label for="NomPrenom">Nom et Prénom</label>
                    <input type="text" id="NomPrenom" list="licencie" name="NomPrenom" class="input" autocomplete="off" v-model="NomPrenom" required/>

                    <datalist id="licencie">
                        <option  v-for="data in coureurs" v-bind:key="data.Id" :value="data.NumLic_Coureur">{{ data.Nom }} {{ data.Prenom }}</option>
                    </datalist>

                    <label for="NumLic">Numéro de licence</label>
                    <input type="text" id="NumLic" name="NumLic" class="input" v-model="NumLic" required />

                    <label for="parcour">1er épreuve</label>
                    <select name="parcour" id="parcour" v-model="epreuve1" required>
                        <option value="">--Choisissez une épreuve--</option>
                        <option v-if="competitions.Cinquante === 'Oui'" value="60m">50m</option>
                        <option v-if="competitions.CinquanteHaies === 'Oui'" value="50 Haies">50m Haies</option>
                        <option v-if="competitions.CinquanteRelais === 'Oui'" value="4X50m">4X50m</option>
                        <option v-if="competitions.Soixante === 'Oui'" value="60m">60m</option>
                        <option v-if="competitions.SoixanteHaies === 'Oui'" value="60m Haies">60m Haies</option>
                        <option v-if="competitions.SoixanteRelais === 'Oui'" value="4X60m">4x60m</option>
                        <option v-if="competitions.QuatreVingt === 'Oui'" value="80m">80m</option>
                        <option v-if="competitions.QuatreVingtHaies === 'Oui'" value="80m Haies">80m Haies</option>
                        <option v-if="competitions.Cent === 'Oui'" value="100m">100m</option>
                        <option v-if="competitions.CentHaies === 'Oui'" value="100m Haies">100m Haies</option>
                        <option v-if="competitions.CentRelais === 'Oui'" value="4X100m">4x100m</option>
                        <option v-if="competitions.CentDixHaies === 'Oui'" value="1110m Haies">110m Haies</option>
                        <option v-if="competitions.CentVingt === 'Oui'" value="120m">120m</option>
                        <option v-if="competitions.DeuxCent === 'Oui'" value="200m">200m</option>
                        <option v-if="competitions.DeuxCentHaies === 'Oui'" value="200m Haies">200m Haies</option>
                        <option v-if="competitions.QuatreCent === 'Oui'" value="400m">400m</option>
                        <option v-if="competitions.QuatreCentHaies === 'Oui'" value="400m Haies">400m Haies</option>
                        <option v-if="competitions.HuitCent === 'Oui'" value="800m">800m</option>
                        <option v-if="competitions.Mille === 'Oui'" value="1000m">1000m</option>
                        <option v-if="competitions.MilleCinq === 'Oui'" value="1500m">1500m</option>
                        <option v-if="competitions.DeuxMille === 'Oui'" value="2000m">2000m</option>
                        <option v-if="competitions.TroisMille === 'Oui'" value="3000m">3000m</option>
                        <option v-if="competitions.TroisMilleSteeple === 'Oui'" value="3000m Steeple">3000m Steeple</option>
                        <option v-if="competitions.CinqMille === 'Oui'" value="5000m">5000m</option>
                        <option v-if="competitions.Longueur === 'Oui'" value="Longueur">Longueur</option>
                        <option v-if="competitions.TripleSaut === 'Oui'" value="Triple-Saut">Triple-Saut</option>
                        <option v-if="competitions.Pentabond === 'Oui'" value="Pentabond">Pentabond</option>
                        <option v-if="competitions.Hauteur === 'Oui'" value="Hauteur">Hauteur</option>
                        <option v-if="competitions.Perche === 'Oui'" value="Perche">Perche</option>
                        <option v-if="competitions.Poids === 'Oui'" value="Poids">Poids</option>
                        <option v-if="competitions.Disque === 'Oui'" value="Disque">Disque</option>
                        <option v-if="competitions.Javelot === 'Oui'" value="Javelot">Javelot</option>
                        <option v-if="competitions.Marteau === 'Oui'" value="Marteau">Marteau</option>
                    </select>

                    <label for="parcour">2ème épreuve</label>
                    <select name="parcour" id="parcour" v-model="epreuve2">
                        <option value="">--Choisissez une épreuve--</option>
                        <option v-if="competitions.Cinquante === 'Oui'" value="60m">50m</option>
                        <option v-if="competitions.CinquanteHaies === 'Oui'" value="50 Haies">50m Haies</option>
                        <option v-if="competitions.CinquanteRelais === 'Oui'" value="4X50m">4X50m</option>
                        <option v-if="competitions.Soixante === 'Oui'" value="60m">60m</option>
                        <option v-if="competitions.SoixanteHaies === 'Oui'" value="60m Haies">60m Haies</option>
                        <option v-if="competitions.SoixanteRelais === 'Oui'" value="4X60m">4x60m</option>
                        <option v-if="competitions.QuatreVingt === 'Oui'" value="80m">80m</option>
                        <option v-if="competitions.QuatreVingtHaies === 'Oui'" value="80m Haies">80m Haies</option>
                        <option v-if="competitions.Cent === 'Oui'" value="100m">100m</option>
                        <option v-if="competitions.CentHaies === 'Oui'" value="100m Haies">100m Haies</option>
                        <option v-if="competitions.CentRelais === 'Oui'" value="4X100m">4x100m</option>
                        <option v-if="competitions.CentDixHaies === 'Oui'" value="1110m Haies">110m Haies</option>
                        <option v-if="competitions.CentVingt === 'Oui'" value="120m">120m</option>
                        <option v-if="competitions.DeuxCent === 'Oui'" value="200m">200m</option>
                        <option v-if="competitions.DeuxCentHaies === 'Oui'" value="200m Haies">200m Haies</option>
                        <option v-if="competitions.QuatreCent === 'Oui'" value="400m">400m</option>
                        <option v-if="competitions.QuatreCentHaies === 'Oui'" value="400m Haies">400m Haies</option>
                        <option v-if="competitions.HuitCent === 'Oui'" value="800m">800m</option>
                        <option v-if="competitions.Mille === 'Oui'" value="1000m">1000m</option>
                        <option v-if="competitions.MilleCinq === 'Oui'" value="1500m">1500m</option>
                        <option v-if="competitions.DeuxMille === 'Oui'" value="2000m">2000m</option>
                        <option v-if="competitions.TroisMille === 'Oui'" value="3000m">3000m</option>
                        <option v-if="competitions.TroisMilleSteeple === 'Oui'" value="3000m Steeple">3000m Steeple</option>
                        <option v-if="competitions.CinqMille === 'Oui'" value="5000m">5000m</option>
                        <option v-if="competitions.Longueur === 'Oui'" value="Longueur">Longueur</option>
                        <option v-if="competitions.TripleSaut === 'Oui'" value="Triple-Saut">Triple-Saut</option>
                        <option v-if="competitions.Pentabond === 'Oui'" value="Pentabond">Pentabond</option>
                        <option v-if="competitions.Hauteur === 'Oui'" value="Hauteur">Hauteur</option>
                        <option v-if="competitions.Perche === 'Oui'" value="Perche">Perche</option>
                        <option v-if="competitions.Poids === 'Oui'" value="Poids">Poids</option>
                        <option v-if="competitions.Disque === 'Oui'" value="Disque">Disque</option>
                        <option v-if="competitions.Javelot === 'Oui'" value="Javelot">Javelot</option>
                        <option v-if="competitions.Marteau === 'Oui'" value="Marteau">Marteau</option>
                    </select>

                    <label for="parcour">3ème épreuve</label>
                    <select name="parcour" id="parcour" v-model="epreuve3">
                        <option value="">--Choisissez une épreuve--</option>
                        <option v-if="competitions.Cinquante === 'Oui'" value="60m">50m</option>
                        <option v-if="competitions.CinquanteHaies === 'Oui'" value="50 Haies">50m Haies</option>
                        <option v-if="competitions.CinquanteRelais === 'Oui'" value="4X50m">4X50m</option>
                        <option v-if="competitions.Soixante === 'Oui'" value="60m">60m</option>
                        <option v-if="competitions.SoixanteHaies === 'Oui'" value="60m Haies">60m Haies</option>
                        <option v-if="competitions.SoixanteRelais === 'Oui'" value="4X60m">4x60m</option>
                        <option v-if="competitions.QuatreVingt === 'Oui'" value="80m">80m</option>
                        <option v-if="competitions.QuatreVingtHaies === 'Oui'" value="80m Haies">80m Haies</option>
                        <option v-if="competitions.Cent === 'Oui'" value="100m">100m</option>
                        <option v-if="competitions.CentHaies === 'Oui'" value="100m Haies">100m Haies</option>
                        <option v-if="competitions.CentRelais === 'Oui'" value="4X100m">4x100m</option>
                        <option v-if="competitions.CentDixHaies === 'Oui'" value="1110m Haies">110m Haies</option>
                        <option v-if="competitions.CentVingt === 'Oui'" value="120m">120m</option>
                        <option v-if="competitions.DeuxCent === 'Oui'" value="200m">200m</option>
                        <option v-if="competitions.DeuxCentHaies === 'Oui'" value="200m Haies">200m Haies</option>
                        <option v-if="competitions.QuatreCent === 'Oui'" value="400m">400m</option>
                        <option v-if="competitions.QuatreCentHaies === 'Oui'" value="400m Haies">400m Haies</option>
                        <option v-if="competitions.HuitCent === 'Oui'" value="800m">800m</option>
                        <option v-if="competitions.Mille === 'Oui'" value="1000m">1000m</option>
                        <option v-if="competitions.MilleCinq === 'Oui'" value="1500m">1500m</option>
                        <option v-if="competitions.DeuxMille === 'Oui'" value="2000m">2000m</option>
                        <option v-if="competitions.TroisMille === 'Oui'" value="3000m">3000m</option>
                        <option v-if="competitions.TroisMilleSteeple === 'Oui'" value="3000m Steeple">3000m Steeple</option>
                        <option v-if="competitions.CinqMille === 'Oui'" value="5000m">5000m</option>
                        <option v-if="competitions.Longueur === 'Oui'" value="Longueur">Longueur</option>
                        <option v-if="competitions.TripleSaut === 'Oui'" value="Triple-Saut">Triple-Saut</option>
                        <option v-if="competitions.Pentabond === 'Oui'" value="Pentabond">Pentabond</option>
                        <option v-if="competitions.Hauteur === 'Oui'" value="Hauteur">Hauteur</option>
                        <option v-if="competitions.Perche === 'Oui'" value="Perche">Perche</option>
                        <option v-if="competitions.Poids === 'Oui'" value="Poids">Poids</option>
                        <option v-if="competitions.Disque === 'Oui'" value="Disque">Disque</option>
                        <option v-if="competitions.Javelot === 'Oui'" value="Javelot">Javelot</option>
                        <option v-if="competitions.Marteau === 'Oui'" value="Marteau">Marteau</option>
                    </select>

                    <button type="submit" class="submit">Envoyer</button>
                </div>
            </form>
            <form ref="myForm" class="formulaire">
                <div class="listeInscrits">
                    <h2>LISTE DES ENGAGÉ(E)S</h2>
                    <!--<RouterLink :to="{name : 'inscrit', params: {id: course.id}}">Liste des inscrits</RouterLink>-->
                </div>
                <div class="containerList">
                    <h4 v-if="competitions.engagements.length == 0">Pas encore d'inscrits</h4>
                    <div v-if="competitions.engagements.length != 0">
                        <table class="styled-table">
                            <thead class="tableHead">
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>1er épreuve</th>
                                <th>2ème épreuve</th>
                                <th>3ème épreuve</th>
                            </thead>
                            <tbody>
                                <tr v-for="data in competitions.engagements" v-bind:key="data.Id">
                                    <td>{{ data.Nom }}</td>
                                    <td>{{ data.Prenom }}</td>
                                    <td>{{ data.Epreuve1 }}</td>
                                    <td>{{ data.Epreuve2 }}</td>
                                    <td>{{ data.Epreuve3 }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="styled-table-mobile">
                            <thead class="tableHead">
                                <th>Nom</th>
                                <th>Prénom</th>
                                <th>épreuve</th>
                            </thead>
                            <tbody>
                                <tr v-for="data in competitions.engagements" v-bind:key="data.Id">
                                    <td>{{ data.Nom }}</td>
                                    <td>{{ data.Prenom }}</td>
                                    <td>{{ data.Epreuve1 }}<span v-if="data.Epreuve2">/{{ data.Epreuve2 }}</span><span v-if="data.Epreuve3">/{{ data.Epreuve3 }}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </form>
        </div>
        <div class="flyer">
            <h2>Liste des épreuves :</h2>
            <div class="listEpreuve" v-if="competitions">
                <div class="epreuve" v-if="competitions.Cinquante === 'Oui'">
                    <h3>50m</h3>
                </div>
                <div class="epreuve" v-if="competitions.CinquanteHaies === 'Oui'">
                    <h3>50m Haies</h3>
                </div>
                <div class="epreuve" v-if="competitions.CinquanteRelais === 'Oui'">
                    <h3>4X50m</h3>
                </div>
                <div class="epreuve" v-if="competitions.Soixante === 'Oui'">
                    <h3>60m</h3>
                </div>
                <div class="epreuve" v-if="competitions.SoixanteHaies === 'Oui'">
                    <h3>60m Haies</h3>
                </div>
                <div class="epreuve" v-if="competitions.SoixanteRelais === 'Oui'">
                    <h3>4X60m</h3>
                </div>
                <div class="epreuve" v-if="competitions.QuatreVingt === 'Oui'">
                    <h3>80m</h3>
                </div>
                <div class="epreuve" v-if="competitions.QuatreVingtHaies === 'Oui'">
                    <h3>80m Haies</h3>
                </div>
                <div class="epreuve" v-if="competitions.Cent === 'Oui'">
                    <h3>100m</h3>
                </div>
                <div class="epreuve" v-if="competitions.CentHaies === 'Oui'">
                    <h3>100m Haies</h3>
                </div>
                <div class="epreuve" v-if="competitions.CentRelais === 'Oui'">
                    <h3>4X100m</h3>
                </div>
                <div class="epreuve" v-if="competitions.CentDixHaies === 'Oui'">
                    <h3>110m Haies</h3>
                </div>
                <div class="epreuve" v-if="competitions.CentVingt === 'Oui'">
                    <h3>120m</h3>
                </div>
                <div class="epreuve" v-if="competitions.DeuxCent === 'Oui'">
                    <h3>200m</h3>
                </div>
                <div class="epreuve" v-if="competitions.DeuxCentHaies === 'Oui'">
                    <h3>200m Haies</h3>
                </div>
                <div class="epreuve" v-if="competitions.QuatreCent === 'Oui'">
                    <h3>400m</h3>
                </div>
                <div class="epreuve" v-if="competitions.QuatreCentHaies === 'Oui'">
                    <h3>400m Haies</h3>
                </div>
                <div class="epreuve" v-if="competitions.HuitCent === 'Oui'">
                    <h3>800m</h3>
                </div>
                <div class="epreuve" v-if="competitions.Mille === 'Oui'">
                    <h3>1000m</h3>
                </div>
                <div class="epreuve" v-if="competitions.MilleCinq === 'Oui'">
                    <h3>1500m</h3>
                </div>
                <div class="epreuve" v-if="competitions.DeuxMille === 'Oui'">
                    <h3>2000m</h3>
                </div>
                <div class="epreuve" v-if="competitions.TroisMille === 'Oui'">
                    <h3>3000m</h3>
                </div>
                <div class="epreuve" v-if="competitions.TroisMilleSteeple === 'Oui'">
                    <h3>3000m Steeple</h3>
                </div>
                <div class="epreuve" v-if="competitions.CinqMille === 'Oui'">
                    <h3>5000m</h3>
                </div>
                <div class="epreuve" v-if="competitions.Longueur === 'Oui'">
                    <h3>Longueur</h3>
                </div>
                <div class="epreuve" v-if="competitions.Pentabond === 'Oui'">
                    <h3>Pentabond</h3>
                </div>
                <div class="epreuve" v-if="competitions.TripleSaut === 'Oui'">
                    <h3>Triple-Saut</h3>
                </div>
                <div class="epreuve" v-if="competitions.Hauteur === 'Oui'">
                    <h3>Hauteur</h3>
                </div>
                <div class="epreuve" v-if="competitions.Perche === 'Oui'">
                    <h3>Perche</h3>
                </div>
                <div class="epreuve" v-if="competitions.Poids === 'Oui'">
                    <h3>Poids</h3>
                </div>
                <div class="epreuve" v-if="competitions.Javelot === 'Oui'">
                    <h3>Javelot</h3>
                </div>
                <div class="epreuve" v-if="competitions.Disque === 'Oui'">
                    <h3>Disque</h3>
                </div>
                <div class="epreuve" v-if="competitions.Marteau === 'Oui'">
                    <h3>Marteau</h3>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .containerBackground{
        width: 100%;
        height: 50vh;
    }
    .containerBackground img{
        width: 100%;
        height: 50vh;
        object-fit: cover;
    }
    .containerShadow{
        position: absolute;
        width: 100%;
        height: 50vh;
        background-color: #1a12128c;
        display: flex;
        flex-direction: column;
    }
    .containerInfo{
        max-width: 65%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
    }
    .containerInfo h2{
        width: 100%;
        color: #fff;
        font-size: 400%;
        font-family: 'ArquitectaBlack';
        text-transform: uppercase;
        margin: 0
    }
    .containerType{
        padding: 1%;
        background-color: #000;
        color: #fff;
        text-align: center;
        border-radius: 5px;
    }
    .containerType h4{
        margin: 0;
        font-size: 120%;
        text-transform: uppercase;
    }
    .event_categ_list{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 1%;
    }
    .event_categ_item{
        display: flex;
        padding: 1%;
        padding-left: 2%;
        padding-right: 2%;
        margin-right: 1%;
        border-radius: 5px;
        text-align: center;
    }
    .event_categ_item h4{
        margin: 0;
        text-align: center;
    }
    .containerForm{
        width: 80%;
        margin: auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 4%;
    }
    .flyer{
        width: 30%;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
    }
    .listEpreuve{
        display: flex;
        flex-wrap: wrap;
    }
    .epreuve{
        background-color: #fff;
        border-radius: 5px;
        margin-right: 2%;
        padding: 2%;
        padding-left: 4%;
        padding-right: 4%;
        margin-bottom: 2%;
    }
    .epreuve h3{
        margin: 0;
    }
    .form{
        width: 65%;
    }
    .date{
        width: 100%;
        margin-top: 2%;
    }
    .date h2{
        text-transform: uppercase;
        font-family: 'ArquitectaBlack';
        font-size: 300%;
        margin: 0;
        margin-bottom: 2%;
    }
    .containerLocation{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 2%;
    }
    .containerLocation img{
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-right: 1%;
    }
    .containerLocation h4{
        margin: 0;
    }
    .formulaire{
        width: 100%;
        margin-top: 5%;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        padding: 2%;
        border-radius: 5px;
        box-shadow: 0 1px 3px rgb(0 0 0 / 15%);
    }
    .listeInscrits{
        width: 100%;
        margin-bottom: 2%;
    }
    .listeInscrits h2{
        text-transform: uppercase;
        font-family: 'ArquitectaBlack';
        font-size: 200%;
        margin: 0;
    }
    .listeInscrits h3{
        color: #f00;
    }
    .listeInscrits a{
        color: #0872b1;
        font-size: 120%;
    }
    .textInput{
        padding: 1%;
        width: 96%;
    }
    .textInput label{
        font-size: 120%;
        font-weight: bold;
        align-self: flex-start;
    }
    .textInput select{
        width: 100%;
        font-size: 120%;
        padding: 1%;
        margin-top: 1%;
        margin-bottom: 3%;
        border-radius: 5px;
    }
    .input{
        width: 98%;
        font-size: 120%;
        padding: 1%;
        margin-top: 2%;
        margin-bottom: 3%;
        border-radius: 5px;
        border: 1px solid #767676;
    }
    .submit{
        width: 100%;
        font-size: 120%;
        padding: 2%;
        margin-top: 2%;
        margin-bottom: 2%;
        border-radius: 5px;
        background-color: #0872b1;
        border: none;
        color: #fff;
        cursor: pointer;
    }
    .containerList{
        padding: 1%;
        width: 96%;
    }
    .containerTable{
        width: 100%;
        height: 380px;
        overflow-y: scroll;
    }
    .tableHead{
        width: 100%;
        text-align: left;
        text-transform: uppercase;
    }
    .styled-table-mobile{
        display: none;
    }
    .styled-table {
        width: 100%;
        border-collapse: collapse;
        font-size: 0.9em;
        font-family: sans-serif;
    }
    .styled-table th,
    .styled-table td {
        padding: 12px 15px;
    }
    .styled-table td:nth-child(3),
    .styled-table th:nth-child(3)
    {
        text-align: center;
    }
    .styled-table td:nth-child(4),
    .styled-table th:nth-child(4)
    {
        text-align: center;
    }
    .styled-table td:nth-child(5),
    .styled-table th:nth-child(5)
    {
        text-align: center;
    }
    @media screen and (max-width : 1024px) {
        .containerBackground{
            height: 30vh;
        }
        .containerBackground img{
            height: 30vh;
        }
        .containerShadow{
            height: 30vh;
        }
        .containerInfo{
            max-width: 90%;
        }
        .containerInfo h2{
            font-size: 210%;
            margin-top: 2%;
        }
        .containerForm{
            width: 86%;
        }
        .form{
            width: 100%;
            font-size: 90%
        }
        .date h2{
            margin-bottom: 4%;
        }
        .formulaire{
            width: 96%;
            margin-top: 10%
        }
        .textInput{
            margin-top: 2%;
        }
        .listeInscrits{
            width: 100%;
        }
        .listeInscrits h2{
            text-align: center;
            margin-top: 2%
        }
        .listeInscrits h3{
            text-align: center
        }
        .styled-table{
            display: none;
        }
        .styled-table-mobile{
            display: block;
            font-size: 90%;
            width: 98%;
            margin-top: 2%;
            border-collapse: collapse;
            font-family: sans-serif;
        }
        .styled-table-mobile td {
            padding: 5px;
        }
        .styled-table-mobile th:nth-child(1){
            width: 33%;
        }
        .styled-table-mobile td:nth-child(3),
        .styled-table-mobile th:nth-child(3)
        {
            text-align: center;
        }
        .flyer{
            margin-top: 5%;
            width: 100%;
        }
    }
</style>
