<script setup lang="ts">
import moment from 'moment'
import 'moment/dist/locale/fr'
import EventsService from '../services/events.service.js'
import CoursesService from '../services/courses.service.js'
import { ref } from 'vue'
moment.locale('fr')

/* eslint-disable */
const props = defineProps({
  events: Object
})

const competitions = ref()
if (props.events.idCompetitions) {
  EventsService.getCompetition(props.events.idCompetitions).then(
  (response: { data: null; }) => {
    competitions.value = response.data
  },
  (error: any) => {
    competitions.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)
}

const courses = ref()
if(props.events.idCourses){
  CoursesService.getCourse(props.events.idCourses).then(
    (response: { data: null; }) => {
      courses.value = response.data
    },
    (error: any) => {
      courses.value =
      (error.response &&
      error.response.data &&
      error.response.data.message) ||
      error.message ||
      error.toString()
    }
  )
}

</script>

<template>
    <div class="itemEvent" v-if="events">
      <div class="event_img">
        <div class="event_date">
          <h3>{{ moment(events.Date).format('ddd') }}</h3>
          <h2>{{ moment(events.Date).format('DD') }}</h2>
          <h3>{{ moment(events.Date).format('MMM') }}</h3>
          <hr>
        </div>
        <img :src="events.Img" alt="">
      </div>
      <div class="event_desc">
        <h2>{{events.Nom}}</h2>
        <div class="event_categ_list">
            <div class="event_categ_item toutes" v-if="events.Toutes == 'Oui'">
                <h4>Toutes catégories</h4>
            </div>
            <div class="event_categ_item eveil" v-if="events.Eveil == 'Oui'">
                <h4>Eveil Athlé</h4>
            </div>
            <div class="event_categ_item poussins" v-if="events.Poussins == 'Oui'">
                <h4>Poussins</h4>
            </div>
            <div class="event_categ_item benjamins" v-if="events.Benjamins == 'Oui'">
                <h4>Benjamins</h4>
            </div>
            <div class="event_categ_item minimes" v-if="events.Minimes == 'Oui'">
                <h4>Minimes</h4>
            </div>
            <div class="event_categ_item cadets" v-if="events.Cadets == 'Oui'">
                <h4>Sprinteur</h4>
            </div>
            <div class="event_categ_item fond" v-if="events.Fond == 'Oui'">
                <h4>Demi-Fond</h4>
            </div>
            <div class="event_categ_item running" v-if="events.Running == 'Oui'">
                <h4>Running</h4>
            </div>
            <div class="event_categ_item autres" v-if="events.Autres == 'Oui'">
                <h4>Autres</h4>
            </div>
            </div>
            <div class="bottomEvent" v-if="competitions">
              <div class="containerLocation">
                    <img src="@/assets/icons/place.png" alt="">
                    <h4>{{ competitions.Adresse }}, {{ competitions.Ville }} ({{ competitions.CodePostal }})</h4>
                </div>
                <div class="containerLocation" >
                    <img src="@/assets/icons/time.png" alt="">
                    <h4>Début de la compétition : {{ moment(competitions.HeureDebut, 'HH:mm:ss').format('HH') }}H{{ moment(competitions.HeureDebut, 'HH:mm:ss').format('mm') }}</h4>
                </div>
                <div class="containerLocation">
                    <img src="@/assets/icons/calendar.png" alt="">
                    <h4>Engagement jusqu'au {{ moment(competitions.DateLimite).format('dddd DD MMMM YYYY') }}</h4>
                </div>
            </div>
            <div class="bottomEvent" v-if="courses">
              <div class="containerLocation">
                    <img src="@/assets/icons/place.png" alt="">
                    <h4>{{ courses.Adresse }}, {{ courses.Ville }} ({{ courses.CodePostal }})</h4>
                </div>
                <div class="containerLocation">
                    <img src="@/assets/icons/calendar.png" alt="">
                    <h4>Engagement jusqu'au {{ moment(courses.DateLimite).format('dddd DD MMMM YYYY') }}</h4>
                </div>
                <div class="containerLocation" v-if="courses.PriseEnCharge === 'Oui'">
                    <img src="@/assets/icons/check.png" alt="">
                    <h4>Prise en charge</h4>
                </div>
                <div class="containerLocation" v-if="courses.PriseEnCharge === 'Non'">
                    <img src="@/assets/icons/check.png" alt="">
                    <h4>Non prise en charge</h4>
                </div>
                
            </div>
            <RouterLink v-if="courses" class="butStyleCourse" :to="{ name: 'course', params: {  id: events.idCourses} }">En savoir plus</RouterLink>
            <RouterLink v-if="competitions" class="butStyleCourse" :to="{ name: 'competition', params: {  id: events.idCompetitions} }">En savoir plus</RouterLink>
        </div>
    </div>
</template>

<style scoped>
.itemEvent{
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4%;
}
.event_date{
    position: absolute;
    width: 4%;
    height: 100px;
    margin: 1%;
    padding: 0.5%;
    text-align: center;
    background-color: #0872b1;
    color: #fff;
    border-radius: 5px;
}
.event_date hr{
  color: #fdce08;
  border: 2px solid #fdce08;
  margin-top: -8%
}
.event_date h2{
  margin-top: 0%;
  margin-bottom: 0;
}
.event_date h3:nth-child(1){
  margin-bottom: 2%;
  margin-top: 10%;
  text-transform: capitalize;
}
.event_date h3{
  margin-top: 2%;
}
.event_desc{
  padding: 2%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 40vh;
}
.event_desc h2{
  margin-top: 1%;
  font-size: 160%;
  margin-bottom: 0%;
  text-transform: uppercase;
  color: #0872b1;
}
.event_categ_list{
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 5vh;
  margin-bottom: 1%;
}
.event_categ_item{
  display: flex;
  padding: 2%;
  padding-left: 4%;
  padding-right: 4%;
  margin-left: 1%;
  border-radius: 5px;
  text-align: center;
}
.event_categ_item h4{
  margin: 0;
  align-self: center;
  text-align: center;
}
.bottomEvent{
  width: 96%;
  display: flex;
  flex-direction: column;
  margin-bottom: 2%;
}
.containerLocation{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2%;
}
.containerLocation img{
  width: 30px;
  height: 30px;
  object-fit: contain;
  margin-right: 1%;
}
.containerLocation h4{
  margin: 0;
  font-size: 95%;
}
.butStyleCourse{
  padding: 2%;
  width: 35%;
  text-decoration: none;
  color: #fff;
  font-size: 100%;
  border-radius: 5px;
  background-color: #0872b1;
  text-align: center;
  text-transform: uppercase;
  align-self: flex-end;
  margin-left: 56%;
  margin-bottom: 1%;
}
.event_lieu{
  width: 60%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  padding: 2%;
}
.event_lieu img{
  object-fit: contain;
  width: 20%;
}
.event_lieu h4{
  margin: 0;
  margin-left: 2%;
  font-size: 100%;
  color: #000;
}
.event_img{
  width: 100%;
}
.event_img img{
  width: 100%;
  height: 200px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  object-fit: cover;
}
.autres{
  background-color: #bdbdbd;
}
.toutes{
  background-color: #bdbdbd
}
@media (min-width: 1024px) and (max-width: 1300px){
  .autres{
    background-color: #bdbdbd;
  }
}
@media screen and (max-width : 1024px) {
  .text_titre {
    margin-top: 5%;
    width: 80%;
    font-size: 80%;
  }
  .evenement{
    width: 86%;
  }
  .event_date{
    margin: 2%;
  }
  .event_date{
    width: 15%;
  }
  .containerLocation{
    margin-top: 4%;
  }
  .butStyleCourse{
    width: 40%;
    margin-left: 52%;
  }
  @media screen and (max-width : 960px) {
    .event_date hr{
      width: 80%;
      margin-top: -20%
    }
  }
}
</style>
