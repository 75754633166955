<template>
    <section class="containerBord">
        <div class="cardClub">
            <h2>licenciés 2024-2025</h2>
        </div>
        <div class="lastActivites">
            <h2>Dernières activités</h2>
            <div class="listActivites">
                <button>Articles</button>
                <button>Albums</button>
                <button>événements</button>
            </div>
        </div>
    </section>
</template>
<style>
    .containerBord{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    .cardClub{
        width: 80%;
        height: 20vh;
        padding: 1%;
        background-color: #fff;
        border-radius: 10px;
    }
    .lastActivites{
        width: 80%;
        height: 60vh;
        padding: 1%;
        background-color: #fff;
        border-radius: 10px;
        margin-top: 2%;
    }
    .listActivites{
        width: 30%;
        display: flex;
        flex-direction: row;
        justify-content: space-between
    }
    .listActivites button{
        border: none;
        background-color: gray;
        border-radius: 5px;
        font-size: 120%;
        color: #fff;
        padding: 2%;
    }
</style>
