import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import BodyView from '@/views/BodyView.vue'
import ChallengeView from '@/views/ChallengeView.vue'
import NewsView from '@/views/NewsView.vue'
import FouleesView from '@/views/FouleesView.vue'
import AlbumsView from '@/views/AlbumsView.vue'
import EventsView from '@/views/EventsView.vue'
import GaleriesView from '@/views/GaleriesView.vue'
import CoursesView from '@/views/CoursesView.vue'
import InscritsView from '@/views/InscritsView.vue'
import EntrainementsView from '@/views/EntrainementsView.vue'
import NosEntraineursView from '@/views/NosEntraineursView.vue'
import TarifsView from '@/views/TarifsView.vue'
import InscriptionsView from '@/views/InscriptionsView.vue'
import BoutiqueView from '@/views/BoutiqueView.vue'
import ComiteView from '@/views/ComiteView.vue'
import BodyAdminView from '@/views/BodyAdminView.vue'
import LoginView from '@/views/LoginView.vue'
import HomeAdminView from '@/views/Admin/HomeAdminView.vue'
import ArticlesAdminView from '@/views/Admin/ArticlesAdminView.vue'
import LicenciesAdminView from '@/views/Admin/LicenciesAdminView.vue'
import LicencieView from '@/views/Admin/LicencieView.vue'
import PisteView from '@/views/PisteView.vue'
import CompetitionsView from '@/views/CompetitionsView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: BodyView,
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView
      },
      {
        path: 'comite',
        name: 'comite',
        component: ComiteView
      },
      {
        path: 'entrainement',
        name: 'entrainement',
        component: EntrainementsView
      },
      {
        path: 'entraineur',
        name: 'entraineur',
        component: NosEntraineursView
      },
      {
        path: 'tarif',
        name: 'tarif',
        component: TarifsView
      },
      {
        path: 'inscription',
        name: 'inscription',
        component: InscriptionsView
      },
      {
        path: 'boutique',
        name: 'boutique',
        component: BoutiqueView
      },
      {
        path: 'challenge',
        name: 'challenge',
        component: ChallengeView
      },
      {
        path: '/course/:id',
        name: 'course',
        component: CoursesView
      },
      {
        path: 'piste',
        name: 'piste',
        component: PisteView
      },
      {
        path: '/competition/:id',
        name: 'competition',
        component: CompetitionsView
      },
      {
        path: 'course/:id/inscrits',
        name: 'inscrit',
        component: InscritsView
      },
      {
        path: 'news',
        name: 'news',
        component: NewsView
      },
      {
        path: 'galeries',
        name: 'galeries',
        component: GaleriesView
      },
      {
        path: 'albums/:id',
        name: 'albums',
        component: AlbumsView
      },
      {
        path: 'events',
        name: 'events',
        component: EventsView
      },
      {
        path: 'foulees',
        name: 'foulees',
        component: FouleesView
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/admin',
    name: 'admin',
    component: BodyAdminView,
    beforeEnter (to, from, next) {
      const loggedIn = localStorage.getItem('user')
      // trying to access a restricted page + not logged in
      // redirect to login page
      if (!loggedIn) {
        next('/login')
      } else {
        next()
      }
    },
    children: [
      {
        path: '/homeAdmin',
        name: 'homeAdmin',
        component: HomeAdminView
      },
      {
        path: '/ArticlesAdmin',
        name: 'articlesAdmin',
        component: ArticlesAdminView
      },
      {
        path: '/LicenciesAdmin',
        name: 'licenciesAdmin',
        component: LicenciesAdminView
      },
      {
        path: '/LicencieView/:id',
        name: 'licencieView',
        component: LicencieView
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
