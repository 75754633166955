<script setup lang="ts">
import moment from 'moment'
import 'moment/dist/locale/fr'
import EventsService from '../services/events.service.js'
import { ref } from 'vue'
moment.locale('fr')

/* eslint-disable */
const props = defineProps({
  competition: Object
})

const events = ref()
EventsService.getOneEvents(props.competition.eventId).then(
  (response: { data: any }) => {
    events.value = response.data
  },
  (error: any) => {
    events.value =
    (error.response &&
    error.response.data &&
    error.response.data.message) ||
    error.message ||
    error.toString()
  }
)

</script>

<template>
<div class="cardCompetition">
            <div class="containerImg">
                <img src="@/assets/piste.jpg" alt="">
                <div class="containerShadow">
                    <h2>{{ moment(props.competition.DateCompetition).format('ddd DD MMM YYYY') }}</h2>
                </div>
            </div>
            <div class="descCompetition">
                <h2>{{ props.competition.NomCompetition }}</h2>
                <div class="event_categ_list" v-if="events">
                    <div class="event_categ_item eveil" v-if="events.Eveil == 'Oui'">
                        <h4>Eveil Athlé</h4>
                    </div>
                    <div class="event_categ_item poussins" v-if="events.Poussins == 'Oui'">
                        <h4>Poussins</h4>
                    </div>
                    <div class="event_categ_item benjamins" v-if="events.Benjamins == 'Oui'">
                        <h4>Benjamins</h4>
                    </div>
                    <div class="event_categ_item minimes" v-if="events.Minimes == 'Oui'">
                        <h4>Minimes</h4>
                    </div>
                    <div class="event_categ_item cadets" v-if="events.Cadets == 'Oui'">
                        <h4>Sprinteur</h4>
                    </div>
                    <div class="event_categ_item fond" v-if="events.Fond == 'Oui'">
                        <h4>Demi-Fond</h4>
                    </div>
                </div>
                <div class="containerIcon">
                    <img src="@/assets/icons/place.png" alt="">
                    <h3>{{ props.competition.Adresse }}, {{ props.competition.Ville }} ({{ props.competition.CodePostal }})</h3>
                </div>
                <div class="containerIcon">
                    <img src="@/assets/icons/time.png" alt="">
                    <h3>Début de la compétion : {{ moment(props.competition.HeureDebut, 'HH:mm:ss').format('HH') }}H{{ moment(props.competition.HeureDebut, 'HH:mm:ss').format('mm') }}</h3>
                </div>
                <div class="containerIcon">
                    <img src="@/assets/icons/calendar.png" alt="">
                    <h3>Engagement jusqu'au {{ moment(props.competition.DateLimite).format('dddd DD MMMM YYYY') }}</h3>
                </div>
                <RouterLink class="butStyleCourse" :to="{ name: 'competition', params: {  id: props.competition.id } }">En savoir plus</RouterLink>
            </div>
        </div>
</template>

<style scoped>
    .cardCompetition{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 4%;
    }
    .containerImg{
        width: 36%;
        height: 36%;
        display: flex;
    }
    .containerShadow{
        position: absolute;
        width: 28.8%;
        height: 36vh;
        border-radius: 5px;
        background-color: #1a12128c;
        display: flex;
        flex-direction: column;
    }
    .containerShadow h2{
        color: #fff;
        align-self: center;
        margin: auto;
        font-size: 200%;
        text-transform: uppercase
    }
    .containerImg img{
        width: 100%;
        height: 36vh;
        border-radius: 5px;
        object-fit: cover;
    }
    .descCompetition{
        width: 62%;
        display: flex;
        flex-wrap: wrap;
    }
    .descCompetition h2{
        width: 100%;
        font-family: 'ArquitectaBlack';
        text-transform: uppercase;
        font-size: 225%;
        margin: 0;
    }
    .event_categ_list{
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-top: 1%;
        margin-bottom: 1%;
    }
    .event_categ_item{
        display: flex;
        padding: 1%;
        padding-left: 2%;
        padding-right: 2%;
        margin-right: 1%;
        border-radius: 5px;
        text-align: center;
        align-self: center;
    }
    .event_categ_item h4{
        margin: 0;
        text-align: center;
    }
    .containerIcon{
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 1%;
    }
    .containerIcon img{
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-right: 1%;
    }
    .containerIcon h3{
        margin: 0;
    }
    .butStyleCourse{
        padding: 1%;
        text-decoration: none;
        color: #fff;
        font-size: 100%;
        border-radius: 5px;
        background-color: #0872b1;
        text-align: center;
        text-transform: uppercase;
        align-self: center;
        margin-top: 2%;
    }
    @media screen and (max-width : 1024px) {
        .containerImg{
            width: 100%;
            height: 20vh;
            margin-top: 5%;
            margin-bottom: 2%;
        }
        .containerShadow{
            width: 90%;
            height: 20vh;
        }
        .containerImg img{
            height: 20vh;
        }
        .descCompetition{
            width: 100%;
            margin-top: 2%;
            font-size: 90%;
        }
        .event_categ_list{
            margin-bottom: 2%;
        }
        .event_categ_item{
            padding: 2%;
        }
        .containerIcon{
            margin-bottom: 2%;
        }
        .butStyleCourse{
            width: 100%;
            padding: 4%;
        }
    }
</style>