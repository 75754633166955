import axios from 'axios'
// import authHeader from './auth-header'

const API_URL = 'https://ogsa-api-3d50c2c1176b.herokuapp.com/api/'
class CoursesService {
  getAllCourses () {
    return axios
      .get(API_URL + 'courses/AllNext')
  }

  getNextCourse () {
    return axios
      .get(API_URL + 'courses/Next')
  }

  getCourse (id) {
    return axios
      .get(API_URL + 'course/' + id)
  }

  getInscrit (id) {
    return axios
      .get(API_URL + 'course/' + id + '/inscrits')
  }

  postInscription (id, body) {
    return axios
      .post(API_URL + 'course/' + id + '/inscrire', body)
  }

  /*
  deleteInscription (id) {
    return axios
      .delete(API_URL + 'inscription/' + id, { headers: authHeader() })
  }

  getInscritExcel (id) {
    return axios
      .get(API_URL + 'course/' + id + '/excel', { headers: authHeader(), responseType: 'blob' })
  }
  */
}

export default new CoursesService()
